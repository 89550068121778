<template>
  <section>
    <h5
      class="my-5 tg-mobile-header-5 lg:tg-desktop-header-5"
      v-text="$t('choose_visit_time')"
    />
    <button
      v-for="slot in timeSlots"
      :key="slot.id"
      :for="`slot_${slot.id}`"
      class="
        my-5 block text-center w-full py-2.5 px-8 tg-mobile-body border border-gray-e9 
        hover:text-brand-primary hover:font-semibold hover:border-brand-primary 
        active:border-gray-e9 active:font-normal active:text-tg-light-text 
        focus:outline-none rounded-md cursor-pointer
      "
      @click="selectTimeSlot(slot.id)"
    >
      {{ formatHHmm(slot.datetime_start) }} -
      {{ formatHHmm(slot.datetime_end) }}
    </button>
  </section>
</template>

<script>
import { formatHHmm } from '@/helpers';

export default {
  name: 'PropertyVisitBuyer',
  props: {
    timeSlots: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatHHmm,
    selectTimeSlot(slotId) {
      this.$router.push({
        name: 'PropertyVisitEnterDetails',
        params: { slotId: slotId }
      });
    }
  }
};
</script>
